import React from 'react'
import useBlogPosts from '../hooks/use-blog-posts'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import { Container, Grid, PagePadding } from '../styles'
import PostPreview from '../components/PostPreview'

const BlogPage = () => {
    const blogPosts = useBlogPosts();
    
    return (
        <Layout>
            <PageBanner title="News / Insights" subtitle="Personal Injury Blog" />
            <Container>
                <PagePadding>
                    <Grid threeCol>
                        {blogPosts.map(post => {
                            return <PostPreview
                                key={post.title}
                                postUrl={post.slug}
                                title={post.title}
                                altText={post.title}
                                imgSrc={post.featuredImage.sharp.fluid}
                                category={post.category}
                            />
                        })}
                    </Grid>
                </PagePadding>
            </Container>
        </Layout>
    )
}

export default BlogPage
