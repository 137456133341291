import { useStaticQuery, graphql } from "gatsby"

const useBlogPosts = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
          filter: {fileAbsolutePath: {regex: "/blog/"}}
          sort: {fields: frontmatter___title, order: ASC}
        ) {
        nodes {
          frontmatter {
            slug
            title
            seoTitle
            metaDesc
            category
            featuredImage {
              sharp: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  
  return data.allMdx.nodes.map(blogPost => ({
    title: blogPost.frontmatter.title,
    slug: blogPost.frontmatter.slug,
    featuredImage: blogPost.frontmatter.featuredImage,
    seoTitle: blogPost.frontmatter.seoTitle,
    metaDesc: blogPost.frontmatter.metaDesc,
    category: blogPost.frontmatter.category,
  }))
}

export default useBlogPosts