import React from 'react'
import {
    PostItem,
    PostThumbnail,
    PostPreviewTitle,
    PostCategory
} from '../styles'
import Image from 'gatsby-image'

const PostPreview = ({ title, postUrl, altText, imgSrc, category }) => {
    return (
            <PostItem to={postUrl}>
                <PostThumbnail>
                    <Image
                        fluid={imgSrc} 
                        alt={altText}
                    />
                </PostThumbnail>
                <div>
                    <PostCategory>{category}</PostCategory>
                    <PostPreviewTitle>{title}</PostPreviewTitle>
                </div>
            </PostItem>
    )
}

export default PostPreview
